import React from 'react';
import Button from '../components/buttons/button';
import { Route } from '../models/site-links';
import SEO from '../components/seo';

const JobApplicationThanksPage = () => {
  return (
    <>
      <SEO title="Thank You - Patronscan" />
      <div className="min-h-screen flex flex-col justify-center items-center">
        <h2 className="font-section-headers mb-5 ml-5 mr-5 text-3xl text-center">
          Thank you for submitting your application!
        </h2>
        <Button buttonId="JobThanks:GoHome" blue text="Go Home" link={Route.Home} />
      </div>
    </>
  );
};

export default JobApplicationThanksPage;
